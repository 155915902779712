.form-table {
    border: 1px solid #0000002b;
    padding: 10px;
    border-radius: 8px;
    overflow: scroll;
}

.form-table thead tr td  {
    font-size: 17px;
    padding-left: 15px;
    padding-right: 15px;
   
    
}


.form-table tbody tr td  {
    font-size: 17px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    margin-top: 20px;
    
}

.form-table tfoot tr td  {
 
  
   padding-left: 15px;
   padding-top: 10px;
    
}

